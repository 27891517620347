// export default {
//     login: ({ username, password }) =>  {
//         const SERVER_URL = process.env.NODE_ENV === 'production' ? window.env.SERVER_URL : window.env.REACT_APP_SERVER_URL
//         const Login_URL = SERVER_URL + '/Login'
//         const request = new Request(Login_URL, {
//             method: 'POST',
//             body: JSON.stringify({ username, password }),
//             headers: new Headers({ 'Content-Type': 'application/json' }),
//         })
//         return fetch(request)
//             .then(response => {
//                 if (response.status < 200 || response.status >= 300) {
//                     throw new Error(response.statusText)
//                 }

//                 //return Promise.resolve()
//                 return response.json()
//             })
//             .then(auth => {
//                 localStorage.setItem('auth', JSON.stringify(auth))
//             })
//             .catch((error) => {
//                 throw new Error('Network error')
//             })
//     },
//    logout: () => {
//         localStorage.removeItem('username')
//         return Promise.resolve()
//     },
//     checkError: ({ status }) => {
//         if (status === 401 || status === 403) {
//             localStorage.removeItem('username')
//             return Promise.reject()
//         }
//         return Promise.resolve()
//     },
//     checkAuth: () => {
//         return localStorage.getItem('username')
//             ? Promise.resolve()
//             : Promise.reject()
//     },
//     getPermissions: () => Promise.resolve(),
//  }
import decodeJwt from 'jwt-decode'
import { datadogRum } from '@datadog/browser-rum'

const authProvider = {
  login: ({ username, password }) => {
    const protocol = window.location.protocol
    const SERVER_URL =
      process.env.NODE_ENV === 'production'
        ? window.env.SERVER_URL
        : window.env.REACT_APP_SERVER_URL
    const Login_URL = protocol + '//' + SERVER_URL + '/Login'
    const request = new Request(Login_URL, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }

        return response.json()
      })
      .then((auth) => {
        //localStorage.setItem('username', username)
        const decodedToken = decodeJwt(auth.token)
        localStorage.setItem('permissions', decodedToken.role)
        localStorage.setItem('policy', decodedToken.policy)
        localStorage.setItem('auth', JSON.stringify(auth))
        localStorage.setItem('name', decodedToken.sub)

        const { id, fullName, avatar, providerid, vendorid } = JSON.parse(
          localStorage.getItem('auth'),
        )
        const localUser = { id: id, name: fullName }
        datadogRum.setUser(localUser)
        datadogRum.setUserProperty('organization', providerid)
        datadogRum.setUserProperty('vendor', vendorid)
      })
      .catch((error) => {
        throw new Error('Network error')
      })
  },
  logout: () => {
    localStorage.removeItem('auth')
    localStorage.removeItem('permissions')
    localStorage.removeItem('name')
    localStorage.removeItem('policy')
    datadogRum.removeUser()
    return Promise.resolve()
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth')
      return Promise.reject()
    }
    /*if(status==undefined)
        {
            localStorage.removeItem('auth')
            return Promise.reject()
        }*/
    return Promise.resolve()
  },
  checkAuth: () => {
    return localStorage.getItem('auth') ? Promise.resolve() : Promise.reject()
  },
  getPermissions: () => {
    const role = localStorage.getItem('permissions')
    return role ? Promise.resolve(role) : Promise.resolve('guest')
  },
  getIdentity: () => {
    try {
      const { id, fullName, avatar, providerid, vendorid } = JSON.parse(
        localStorage.getItem('auth'),
      )
      return Promise.resolve({ id, fullName, avatar, providerid, vendorid })
    } catch (error) {
      return Promise.reject(error)
    }
  },
}

export default authProvider
