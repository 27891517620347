import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App_2'
import * as serviceWorker from './serviceWorker'

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store'

// import Datadog from 'react-datadog'
import { datadogRum } from '@datadog/browser-rum'

React.icons = icons

datadogRum.init({
  applicationId: `${window.env.DD_APPLICATION_ID}`,
  clientToken: `${window.env.DD_CLIENT_TOKEN}`,
  site: 'datadoghq.eu',
  service: `${window.env.DD_SERVICE}`,
  version: `${window.env.VERSION}`,
  env: `${window.env.DD_ENV}`,
  actionNameAttribute: 'hum_dd_action',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100, // if not included, the default is 100
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  trackFrustrations: true,
  sessionReplayRecording: true,
})

ReactDOM.render(
  <Provider store={store}>
    {/* <Datadog
      applicationId={`${window.env.DD_APPLICATION_ID}`}
      clientToken={`${window.env.DD_CLIENT_TOKEN}`}
      service={`${window.env.DD_SERVICE}`}
      version={`${window.env.VERSION}`}
      site="datadoghq.eu"
      env={`${window.env.DD_ENV}`}
      useSecureSessionCookie
      sessionReplayRecording
      trackUserInteractions
      actionNameAttribute="hum_dd_action"
    > */}
    <App />
    {/* </Datadog> */}
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
