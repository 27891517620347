import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
// UPDATE
import authProvider from './authProvider'
import { createHashHistory } from 'history'
import createAdminStore from './createAdminStore'
import { Provider } from 'react-redux'

import {
  AuthContext,
  DataProviderContext,
  TranslationProvider,
  Resource,
  Notification,
} from 'react-admin'
import restProvider from 'ra-data-simple-rest'

import { fetchUtils } from 'ra-core'
import simpleRestProvider from 'ra-data-simple-rest'

import { v4 as uuid } from 'uuid'
//END UPDATE

//DATA provider
//const MyLayout = React.lazy(() => import('./MyLayout.js'))
//const AdminCustomLayout = React.lazy(() => import('./customlayout/AdminCustomLayout.js'))
const fetchJson = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }

  // add your own headers here
  const auth = JSON.parse(localStorage.getItem('auth'))
  options.headers.set('Authorization', 'Bearer ' + auth.token)
  options.headers.set('CorrelationId', uuid())
  return fetchUtils.fetchJson(url, options)
}
//

//
const protocol = window.location.protocol
const SERVER_URL =
  process.env.NODE_ENV === 'production'
    ? `${protocol}//${window.env.SERVER_URL}`
    : `${protocol}//${window.env.REACT_APP_SERVER_URL}`

const dataProvider = simpleRestProvider(SERVER_URL, fetchJson, 'X-Total-Count')
//const authProvider = () => Promise.resolve()
const history = createHashHistory()
//

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Logout = React.lazy(() => import('./views/pages/logout/Logout'))
const Providers = React.lazy(() => import('./views/components/providers/Providers'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const UserSettings = React.lazy(() => import('./views/components/user/Profile'))

const App = () => (
  <Provider
    store={createAdminStore({
      authProvider,
      dataProvider,
      history,
    })}
  >
    <AuthContext.Provider value={authProvider}>
      <DataProviderContext.Provider value={dataProvider}>
        <HashRouter history={history}>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </DataProviderContext.Provider>
    </AuthContext.Provider>
  </Provider>
)
/*
class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    )
  }
}
*/
export default App
